<template>
  <div
    class="invoice-create create-form"
    v-if="getPermission('invoice:create')"
  >
    <v-form
      ref="invoiceForm"
      v-model.trim="formValid"
      lazy-validation
      v-on:submit.stop.prevent="onSubmit"
    >
      <v-row>
        <v-col cols="12">
          <v-container fluid class="white-background main-create-page">
            <div class="p-5 pb-0">
              <v-row>
                <v-col cols="7" class="pt-0">
                  <v-skeleton-loader
                    v-if="pageLoading"
                    class="custom-skeleton custom-skeleton-full-width"
                    type="button"
                  ></v-skeleton-loader>
                  <h1 v-else class="form-title d-flex">
                    Invoice For
                    <span
                      class="m-0 form-title-create-link pl-2"
                      :class="{
                        'custom-nowrap-ellipsis': !lodash.isEmpty(customer),
                      }"
                      link
                    >
                      <template v-if="lodash.isEmpty(customer)"
                        >Client Name</template
                      >
                      <template v-else>{{ customer.display_name }}</template>
                      <v-icon
                        link
                        large
                        color="cyan"
                        class="mx-2"
                        v-if="lodash.isEmpty(customer) || isDuplicateMode"
                        >mdi-plus-circle-outline</v-icon
                      >
                    </span>
                  </h1>
                </v-col>
                <v-col cols="5" class="pt-0 text-right">
                  <v-btn
                    v-on:click="goBack"
                    :disabled="formLoading || pageLoading"
                    class="mx-2 custom-grey-border custom-bold-button"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    :disabled="!formValid || formLoading || pageLoading"
                    :loading="formLoading"
                    class="mx-2 custom-bold-button white--text"
                    v-on:click="onSubmit"
                    color="cyan"
                  >
                    Save Invoice
                  </v-btn>
                  <v-menu
                    v-if="false && !isUpdateMode"
                    content-class="custom-menu-list"
                    transition="slide-y-transition"
                    bottom
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="!formValid || formLoading || pageLoading"
                        :loading="formLoading"
                        class="mx-2 custom-bold-button white--text"
                        color="cyan"
                        v-bind="attrs"
                        v-on="on"
                      >
                        Save and... <v-icon right>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item link v-on:click="onSubmit('send_as_email')">
                        <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                          <v-icon class="icon-default-blue"
                            >mdi-email-send</v-icon
                          >
                        </v-list-item-icon>
                        <v-list-item-title class="font-weight-500 font-size-14"
                          >Send as Email</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item
                        v-if="false"
                        link
                        v-on:click="onSubmit('mark_as_paid')"
                      >
                        <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                          <v-icon class="icon-default-blue"
                            >mdi-check-all</v-icon
                          >
                        </v-list-item-icon>
                        <v-list-item-title class="font-weight-500 font-size-14"
                          >Mark as Paid</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </div>
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow"
              style="max-height: 60vh; position: relative"
            >
              <div class="p-5 pt-0">
                <v-row>
                  <v-col cols="7" class="pb-0">
                    <v-container fluid>
                      <div>
                        <label class="font-weight-700 font-size-16"
                          >Invoice Subject</label
                        >
                        <v-text-field
                          v-model.trim="invoiceCreate.title"
                          dense
                          filled
                          color="cyan"
                          label="Subject"
                          :disabled="isPageLoading"
                          solo
                          flat
                          :rules="[
                            validateRules.required(
                              invoiceCreate.title,
                              'Invoice Subject'
                            ),
                            validateRules.minLength(
                              invoiceCreate.title,
                              'Invoice Subject',
                              1
                            ),
                            validateRules.maxLength(
                              invoiceCreate.title,
                              'Invoice Subject',
                              100
                            ),
                          ]"
                        ></v-text-field>
                      </div>
                      <div>
                        <v-layout class="my-4">
                          <v-flex md6 class="mr-2 custom-border-right">
                            <table width="100%">
                              <tr>
                                <td class="font-weight-600">
                                  Service Location
                                  <v-icon
                                    class="ml-3"
                                    v-on:click="customerPropertyDialog = true"
                                    color="cyan"
                                    small
                                    >mdi-pencil</v-icon
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td class="py-0">
                                  {{ property.property_address }}
                                </td>
                              </tr>
                              <tr>
                                <td class="font-weight-600">
                                  Contact details
                                  <v-icon
                                    v-if="false"
                                    class="ml-3"
                                    v-on:click="
                                      person_type = 'property';
                                      customerPersonDialog = true;
                                    "
                                    color="cyan"
                                    small
                                    >mdi-pencil</v-icon
                                  >
                                </td>
                              </tr>
                              <tr v-if="property_contact_person.display_name">
                                <td class="py-0">
                                  {{ property_contact_person.display_name }}
                                </td>
                              </tr>
                              <tr v-if="property_contact_person.primary_phone">
                                <td class="py-0">
                                  {{ property_contact_person.primary_phone }}
                                </td>
                              </tr>
                              <tr v-if="property_contact_person.primary_email">
                                <td class="py-0">
                                  <v-layout>
                                    <v-flex>{{
                                      property_contact_person.primary_email
                                    }}</v-flex>
                                    <v-flex>
                                      <v-tooltip
                                        top
                                        content-class="custom-top-tooltip"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-switch
                                            class="m-0 p-0"
                                            color="cyan"
                                            dense
                                            v-model="
                                              invoiceCreate.property_person_notify
                                            "
                                            inset
                                          ></v-switch>
                                        </template>
                                        <span
                                          v-if="
                                            invoiceCreate.property_person_notify ==
                                            1
                                          "
                                          >Send Notification</span
                                        >
                                        <span v-else>No Notification</span>
                                      </v-tooltip>
                                    </v-flex>
                                  </v-layout>
                                </td>
                              </tr>
                            </table>
                          </v-flex>
                          <v-flex md6 class="ml-2">
                            <table width="100%">
                              <tr>
                                <td class="font-weight-600">
                                  Billing Address
                                  <v-icon
                                    class="ml-3"
                                    v-on:click="customerBillingDialog = true"
                                    color="cyan"
                                    small
                                    >mdi-pencil</v-icon
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td class="py-0">
                                  {{ billing.property_address }}
                                </td>
                              </tr>
                              <tr>
                                <td class="font-weight-600">
                                  Contact details
                                  <v-icon
                                    class="ml-3"
                                    v-on:click="
                                      person_type = 'billing';
                                      customerPersonDialog = true;
                                    "
                                    color="cyan"
                                    small
                                    >mdi-pencil</v-icon
                                  >
                                </td>
                              </tr>
                              <tr v-if="billing_contact_person.display_name">
                                <td class="py-0">
                                  {{ billing_contact_person.display_name }}
                                </td>
                              </tr>
                              <tr v-if="billing_contact_person.primary_phone">
                                <td class="py-0">
                                  {{ billing_contact_person.primary_phone }}
                                </td>
                              </tr>
                              <tr v-if="billing_contact_person.primary_email">
                                <td class="py-0">
                                  <v-layout>
                                    <v-flex>{{
                                      billing_contact_person.primary_email
                                    }}</v-flex>
                                    <v-flex>
                                      <v-tooltip
                                        top
                                        content-class="custom-top-tooltip"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-switch
                                            class="m-0 p-0"
                                            color="cyan"
                                            dense
                                            v-model="
                                              invoiceCreate.billing_person_notify
                                            "
                                            inset
                                          ></v-switch>
                                        </template>
                                        <span
                                          v-if="
                                            invoiceCreate.billing_person_notify ==
                                            1
                                          "
                                          >Send Notification</span
                                        >
                                        <span v-else>No Notification</span>
                                      </v-tooltip>
                                    </v-flex>
                                  </v-layout>
                                </td>
                              </tr>
                            </table>
                          </v-flex>
                        </v-layout>
                      </div>
                    </v-container>
                  </v-col>
                  <v-col cols="8" class="pb-0 col-lg-5">
                    <v-container fluid>
                      <table class="width-100">
                        <tr>
                          <td
                            colspan="3"
                            class="font-weight-700 font-size-16 pb-2"
                          >
                            Invoice details
                          </td>
                        </tr>
                        <tr>
                          <td class="font-size-16 py-2" width="200">
                            Invoice number
                          </td>
                          <td
                            class="font-weight-700 font-size-16 pb-2"
                            width="150"
                            :colspan="isUpdateMode ? 2 : 1"
                          >
                            <v-skeleton-loader
                              v-if="pageLoading"
                              class="custom-skeleton"
                              type="text"
                            ></v-skeleton-loader>
                            <template v-else>{{
                              invoiceCreate.barcode
                            }}</template>
                          </td>
                          <td v-if="!isUpdateMode" class="font-size-16 pb-2">
                            <v-btn
                              text
                              small
                              :disabled="isPageLoading"
                              class="custom-bold-button"
                              v-on:click="barcodeDialog = true"
                              color="cyan"
                            >
                              Change
                            </v-btn>
                          </td>
                        </tr>
                        <tr>
                          <td class="pb-2 font-size-16">Reference #</td>
                          <td colspan="2" class="py-2">
                            <v-text-field
                              dense
                              color="cyan"
                              filled
                              :disabled="isPageLoading"
                              v-model.trim="invoiceCreate.reference"
                              label="Reference #"
                              :rules="[
                                validateRules.minLength(
                                  invoiceCreate.reference,
                                  'Reference #',
                                  1
                                ),
                                validateRules.maxLength(
                                  invoiceCreate.reference,
                                  'Reference #',
                                  100
                                ),
                              ]"
                              solo
                              flat
                              hide-details
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr>
                          <td class="pb-2 font-size-16">PO Number</td>
                          <td colspan="2" class="py-2">
                            <v-text-field
                              dense
                              color="cyan"
                              filled
                              :disabled="isPageLoading"
                              v-model.trim="invoiceCreate.po_number"
                              label="PO Number"
                              :rules="[
                                validateRules.minLength(
                                  invoiceCreate.po_number,
                                  'PO Number',
                                  1
                                ),
                                validateRules.maxLength(
                                  invoiceCreate.po_number,
                                  'PO Number',
                                  100
                                ),
                              ]"
                              solo
                              flat
                              hide-details
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr>
                          <td class="pb-2 font-size-16">Issued date</td>
                          <td colspan="2" class="py-2">
                            <div
                              v-if="invoiceCreate.issued_type == 1"
                              class="d-flex"
                            >
                              <DatePicker
                                :pageLoading="isPageLoading"
                                solo
                                :placeholder="'Issued date'"
                                v-on:update:date-picker="setIssuedDate"
                              ></DatePicker>
                              <div class="my-auto mx-2">
                                <v-icon
                                  link
                                  color="red lighten-1"
                                  v-on:click="invoiceCreate.issued_type = 0"
                                  >mdi-close</v-icon
                                >
                              </div>
                            </div>
                            <v-btn
                              text
                              :disabled="isPageLoading"
                              class="custom-bold-button"
                              v-on:click="invoiceCreate.issued_type = 1"
                              color="cyan"
                              v-else
                            >
                              Date Sent
                            </v-btn>
                          </td>
                        </tr>
                        <tr>
                          <td class="pb-2 font-size-16">Payment due</td>
                          <td colspan="2" class="py-2">
                            <v-select
                              :items="paymentDueList"
                              dense
                              filled
                              solo
                              flat
                              hide-details
                              :disabled="isPageLoading"
                              v-model.trim="invoiceCreate.payment_due"
                              label="Payment due"
                              color="cyan"
                              item-text="text"
                              item-value="id"
                              item-color="cyan"
                            ></v-select>
                          </td>
                        </tr>
                        <tr v-if="invoiceCreate.payment_due == 5">
                          <td class="pb-2 font-size-16">Due date</td>
                          <td colspan="2" class="py-2">
                            <DatePicker
                              :placeholder="'Due date'"
                              solo
                              :pageLoading="isPageLoading"
                              v-on:update:date-picker="setDueDate"
                            ></DatePicker>
                          </td>
                        </tr>
                        <template v-if="false">
                          <tr
                            v-for="(field, index) in customFields"
                            :key="index"
                            :class="{
                              'custom-border-bottom': customFields[index + 1],
                            }"
                          >
                            <td class="pb-2 font-size-16">
                              {{ field.field_name }}
                            </td>
                            <td colspan="2" class="py-2">
                              <CustomField
                                :field="field"
                                :pageLoading="isPageLoading"
                                :index="index"
                              ></CustomField>
                            </td>
                          </tr>
                          <tr v-if="false && customFields.length < 5">
                            <td colspan="3">
                              <v-btn
                                :disabled="isPageLoading"
                                class="my-2 mr-2 custom-bold-button white--text"
                                color="cyan"
                                small
                                solo
                                flat
                                v-on:click="customFieldDialog = true"
                                ><strong class="font-size-14"
                                  >Add Custom Field
                                </strong>
                              </v-btn>
                              <template v-if="customFieldDialog">
                                <CreateCustomField
                                  :fieldModule="2"
                                  v-on:update:custom-field="getCustomField"
                                  v-on:close:custom-field="
                                    customFieldDialog = false
                                  "
                                ></CreateCustomField>
                              </template>
                            </td>
                          </tr>
                        </template>
                      </table>
                    </v-container>
                  </v-col>
                  <v-col
                    cols="12"
                    class="pt-0"
                    v-if="getPermission('line-item:create')"
                  >
                    <v-container fluid>
                      <v-card
                        flat
                        class="custom-grey-border remove-border-radius"
                      >
                        <v-card-title class="headline grey lighten-4">
                          <h3
                            class="font-weight-700 custom-headline color-custom-blue"
                          >
                            Line Items
                          </h3>
                        </v-card-title>
                        <v-card-text class="p-6 font-size-16">
                          <v-row dense>
                            <v-col cols="12" class="pt-0 pb-0">
                              <InvoiceLineItemNew
                                is-invoice
                                can-update
                                :db-line-items="dbLineItem"
                                :discount-value="invoiceCreate.discount_value"
                                :discount-value-type="invoiceCreate.discount_value_type"
                                :apply-tax="invoiceCreate.tax_applied"
                                :adjustment-value="invoiceCreate.adjustment"
                                :tax-value="taxValue"
                                v-on:update:line-item="updateLineItem($event)"
                                v-on:update:line-item-calculation="
                                  updateLineItemCalculation($event)
                                "
                              ></InvoiceLineItemNew>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-container>
                  </v-col>
                  <v-col cols="12">
                    <InvoicePaymentTerms
                      :updateData="updateData.InvoicePaymentTerms"
                      :pageLoading="isPageLoading"
                    ></InvoicePaymentTerms>
                  </v-col>
                  <v-col cols="12">
                    <InvoiceTermsConditions
                      :updateData="updateData.InvoiceTermsConditions"
                      :pageLoading="isPageLoading"
                    ></InvoiceTermsConditions>
                  </v-col>
                  <v-col cols="12">
                    <InvoiceNotesAttachment
                      :updateData="updateData.InvoiceNotesAttachment"
                      :pageLoading="isPageLoading"
                    ></InvoiceNotesAttachment>
                  </v-col>
                </v-row>
              </div>
            </perfect-scrollbar>
          </v-container>
        </v-col>
      </v-row>
    </v-form>
    <template v-if="customerPersonDialog">
      <CustomerPersonDialog
        disabled-auto-select
        :customerPersonDialog="customerPersonDialog"
        :customer="customer.id"
        v-on:close="closeDialog"
        v-on:selectCustomerPerson="selectCustomerPerson"
      ></CustomerPersonDialog>
    </template>
    <template v-if="customerPropertyDialog">
      <CustomerPropertyDialog
        disabled-auto-select
        :customerPropertyDialog="customerPropertyDialog"
        :customer="customer.id"
        v-on:close="closeDialog"
        v-on:selectCustomerProperty="selectCustomerProperty"
      ></CustomerPropertyDialog>
    </template>
    <template v-if="customerBillingDialog">
      <CustomerBillingDialog
        disabled-auto-select
        :customerBillingDialog="customerBillingDialog"
        :customer="customer.id"
        v-on:close="closeDialog"
        v-on:selectCustomerBilling="selectCustomerBilling"
      ></CustomerBillingDialog>
    </template>
    <BarcodeSetting
      endpoint="invoice"
      :barcodeDialog="barcodeDialog"
      :dialogWidth="600"
      :barcodeSetting="barcodeSetting"
      v-on:close:barcode-dialog="barcodeDialog = false"
    ></BarcodeSetting>
  </div>
</template>

<script>
import CreateInvoiceMixin from "@/core/lib/invoice/create.invoice.mixin";
import InvoiceLineItemNew from "@/view/pages/partials/Line-Item-New.vue";
import InvoiceTermsConditions from "@/view/pages/partials/Terms-Conditions.vue";
import InvoicePaymentTerms from "@/view/pages/partials/Payment-Terms.vue";
import InvoiceNotesAttachment from "@/view/pages/partials/Notes-Attachment.vue";
import BarcodeSetting from "@/view/pages/partials/Barcode-Setting.vue";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import CustomField from "@/view/pages/custom-field/Custom-Field.vue";
import CreateCustomField from "@/view/pages/custom-field/Create-Custom-Field.vue";
import CustomerPersonDialog from "@/view/pages/partials/Select-Customer-Person.vue";
import CustomerPropertyDialog from "@/view/pages/partials/Select-Customer-Property.vue";
import CustomerBillingDialog from "@/view/pages/partials/Select-Customer-Billing.vue";

export default {
  mixins: [CreateInvoiceMixin],
  name: "invoice-create",
  title: "Create Invoice",
  components: {
    InvoiceLineItemNew,
    InvoiceTermsConditions,
    InvoicePaymentTerms,
    InvoiceNotesAttachment,
    BarcodeSetting,
    DatePicker,
    CustomField,
    CreateCustomField,
    CustomerPersonDialog,
    CustomerPropertyDialog,
    CustomerBillingDialog,
  },
};
</script>
