import moment from "moment-timezone";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import CommonMixin from "@/core/plugins/common-mixin";
import { TermConditionEventBus } from "@/core/lib/term.condition.lib";
import { NoteAttachmentEventBus } from "@/core/lib/note.attachment.lib";
import {
  GET,
  PUT,
  POST,
  QUERY,
  CLEAR_ERROR,
} from "@/core/services/store/request.module";
import LineItemMixin from "@/core/lib/line-item/line.item.mixin";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin, LineItemMixin],
  data() {
    return {
      dbLineItem: [],
      paymentDueList: [],
      customFieldDialog: false,
      customFields: new Array(),
      invoiceCustomFields: new Array(),
      isUpdateMode: false,
      isDuplicateMode: false,
      invoice: 0,
      jobId: 0,
      visitId: 0,
      duplicateInvoice: 0,
      invoiceArr: {},
      pageLoading: true,
      changeAttention: false,
      changeIssuedDate: false,
      barcodeDialog: false,
      lineItem: [],
      lineItemCalculation: {},
      termsCondition: null,
      paymentTerms: null,
      noteAttachment: {},
      customer: {},
      property: {},
      billing: {},
      contact_person: {},
      property_contact_person: {},
      billing_contact_person: {},
      barcodeSetting: {},
      taxValue: 9,
      updateData: {
        InvoiceLineItem: new Array(),
        InvoiceLineItemCalculation: new Object(),
        InvoiceTermsConditions: new Object(),
        InvoiceNotesAttachment: new Object(),
      },
      invoiceCreate: {
        po_number: null,
        reference: null,
        customer: 0,
        project: 0,
        property: 0,
        billing: 0,
        contact_person: 0,
        property_contact_person: 0,
        billing_contact_person: 0,
        title: null,
        quotation: null,
        ticket: null,
        visit: null,
        attention: null,
        admin_remark: null,
        client_remark: null,
        term_conditions: null,
        payment_terms: null,
        additional_remarks: null,
        discount_value: null,
        adjustment: null,
        discount_type: null,
        discount_value_type: null,
        notify_admin: 0,
        notify_customer: 0,
        property_person_notify: 1,
        billing_person_notify: 0,
        issued_type: 0,
        payment_due: 2,
        invoice_date: moment().format("YYYY-MM-DD"),
        due_date: moment().format("YYYY-MM-DD"),
      },
      customerPersonDialog: false,
      customerBillingDialog: false,
      customerPropertyDialog: false,
      person_type: null,
    };
  },
  methods: {
    closeDialog() {
      this.customerPersonDialog = false;
      this.customerBillingDialog = false;
      this.customerPropertyDialog = false;
    },
    selectCustomerPerson(param) {
      if (this.person_type == "property") {
        if (this.invoiceCreate.property_contact_person == param) {
          this.closeDialog();
          return false;
        }
        this.invoiceCreate.property_contact_person = param;
      }
      if (this.person_type == "billing") {
        if (this.invoiceCreate.billing_contact_person == param) {
          this.closeDialog();
          return false;
        }
        this.invoiceCreate.billing_contact_person = param;
      }
      this.closeDialog();
      this.pushToRouteInvoice();
    },
    selectCustomerProperty(param) {
      if (this.invoiceCreate.property == param) {
        this.closeDialog();
        return false;
      }
      this.invoiceCreate.property = param;
      this.closeDialog();
      this.pushToRouteInvoice();
    },
    selectCustomerBilling(param) {
      if (this.invoiceCreate.billing == param) {
        this.closeDialog();
        return false;
      }
      this.invoiceCreate.billing = param;
      this.closeDialog();
      this.pushToRouteInvoice();
    },
    pushToRouteInvoice() {
      this.$router
        .replace(
          this.getDefaultRoute("invoice.create", {
            query: {
              customer: this.invoiceCreate.customer ?? undefined,
              property_contact_person:
                this.invoiceCreate.property_contact_person ?? undefined,
              billing_contact_person:
                this.invoiceCreate.billing_contact_person ?? undefined,
              property: this.invoiceCreate.property ?? undefined,
              billing: this.invoiceCreate.billing ?? undefined,
              job: this.jobId ?? undefined,
              visit: this.visitId ?? undefined,
            },
          })
        )
        .then(() => {
          this.getAttributes([
            "customer",
            "property_contact_person",
            "billing_contact_person",
            "property",
            "billing",
          ]);
        });
    },
    getVisitDetail() {
      if (this.jobId && this.visitId) {
        this.$store
          .dispatch(GET, {
            url: `visit/${this.visitId}`,
          })
          .then(({ data }) => {
            this.invoiceCreate.reference = data.barcode;
            this.invoiceCreate.po_number = data.ticket.po_number;
            this.invoiceCreate.title = `Invoice for Visit# ${data.barcode} - ${data.title}`;
            this.invoiceCreate.ticket = data.ticket.id;
            this.invoiceCreate.visit = data.id;
            this.invoiceCreate.discount_value = data.ticket.discount_value;
            this.invoiceCreate.discount_value_type =
              data.ticket.discount_value_type;
            this.invoiceCreate.tax_applied = data.ticket.tax_applied;
            this.invoiceCreate.adjustment = data.ticket.adjustment;
            // this.taxValue = data.ticket.tax_value;
          })
          .catch((error) => {
            this.logError(error);
            this.goBack();
          })
          .finally(() => {
            this.getLineItems({ job: this.jobId, visit: this.visitId });
          });
      } else {
        this.goBack();
      }
    },
    getLineItems(data) {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "line-item",
          data,
        })
        .then(async ({ data }) => {
          if (
            _this.lodash.isEmpty(data.rows) === false &&
            _this.lodash.isArray(data.rows)
          ) {
            let result = [];

            for (let i = 0; i < data.rows.length; i++) {
              result.push({
                id: null,
                group: data.rows[i].group_me,
                group_primary: data.rows[i].group_primary,
                product: data.rows[i].product.name,
                product_id: data.rows[i].product.product,
                product_type: data.rows[i].product.product_type,
                description: data.rows[i].description,
                rate: data.rows[i].rate,
                quantity: data.rows[i].quantity,
                uom:
                  data.rows[i].product && data.rows[i].product.unit
                    ? data.rows[i].product.unit.text
                    : null,
              });
            }

            _this.dbLineItem = result;

            _this.updateData.InvoiceLineItem = result;
          }
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        });
    },
    getWarrantyData(rows) {
      const _this = this;
      return new Promise((resolve) => {
        if (
          _this.lodash.isEmpty(rows) === false &&
          _this.lodash.isArray(rows)
        ) {
          let result = rows.map((row) => {
            return {
              id: undefined,
              days: row.days,
              field: row.field,
              value: row.value,
              warranty: row.warranty,
            };
          });
          resolve(result);
        } else {
          resolve([]);
        }
      });
    },
    setIssuedDate(param) {
      this.invoiceCreate.invoice_date = param;
    },
    setDueDate(param) {
      this.invoiceCreate.due_date = param;
    },
    updateBarcodeSetting() {
      this.barcodeDialog = false;
    },
    getAttributes(attributes) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(QUERY, {
          url: "invoice/options/" + _this.invoice,
          data: {
            duplicate: +_this.isDuplicateMode,
            attribute: attributes,
            customer: _this.invoiceCreate.customer || null,
            property_contact_person:
              _this.invoiceCreate.property_contact_person || null,
            billing_contact_person:
              _this.invoiceCreate.billing_contact_person || null,
            property: _this.invoiceCreate.property || null,
            billing: _this.invoiceCreate.billing || null,
          },
        })
        .then(({ data }) => {
          if (_this.lodash.isEmpty(data) === false) {
            if (_this.lodash.isEmpty(data.invoice) === false) {
              _this.invoiceCreate = data.invoice;
              _this.updateData.InvoiceLineItemCalculation = data.invoice;
              _this.updateData.InvoiceTermsConditions = data.invoice;
              _this.updateData.InvoicePaymentTerms = data.invoice;
              _this.updateData.InvoiceNotesAttachment = data.invoice;
              _this.updateData.InvoiceLineItem = data.invoice.line_items;
              _this.$store.dispatch(SET_BREADCRUMB, [
                {
                  title: "Invoice",
                  route: "invoice",
                },
                {
                  title: "Update",
                },
                {
                  title: _this.invoiceCreate.barcode,
                },
              ]);
            }

            if (_this.lodash.isEmpty(data.customer) === false) {
              _this.invoiceCreate.customer = data.customer.id;
              _this.customer = data.customer;
            }

            if (_this.lodash.isEmpty(data.payment_terms) === false) {
              _this.paymentDueList = data.payment_terms;
            }

            if (_this.lodash.isEmpty(data.property) === false) {
              _this.invoiceCreate.property = data.property.id;
              _this.property = data.property;
            }

            if (_this.lodash.isEmpty(data.billing) === false) {
              _this.invoiceCreate.billing = data.billing.id;
              _this.billing = data.billing;
            }

            if (_this.lodash.isEmpty(data.property_contact_person) === false) {
              _this.invoiceCreate.property_contact_person =
                data.property_contact_person.id;
              _this.property_contact_person = data.property_contact_person;
            }

            if (_this.lodash.isEmpty(data.billing_contact_person) === false) {
              _this.invoiceCreate.billing_contact_person =
                data.billing_contact_person.id;
              _this.billing_contact_person = data.billing_contact_person;
            }

            if (_this.lodash.isEmpty(data.barcode) === false) {
              _this.invoiceCreate.barcode = data.barcode;
            }

            if (_this.lodash.isEmpty(data.barcode_setting) === false) {
              _this.barcodeSetting = data.barcode_setting;

              /*_this.updateData.InvoiceTermsConditions = {
                term_conditions: _this.barcodeSetting.terms_conditions,
              };

              _this.updateData.InvoiceNotesAttachment = {
                admin_remark: _this.barcodeSetting.admin_note,
                client_remark: _this.barcodeSetting.client_note,
              };*/
            }

            if (_this.lodash.isEmpty(data.custom_field) === false) {
              _this.customFields = data.custom_field;
            }
          }
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateLineItem(rows) {
      this.lineItem = rows;
    },
    updateLineItemCalculation(row) {
      this.lineItemCalculation.taxApplied = row.apply_tax;
      this.lineItemCalculation.discountValue = row.discount_value;
      this.lineItemCalculation.adjustmentAmount = row.adjustment;
      this.lineItemCalculation.discountType = 1;
      this.lineItemCalculation.discountValueType = row.discount_value_type;
    },
    onSubmit(type) {
      const _this = this;

      let validateLineItem = _this.lodash.compact(
        _this.lodash.map(_this.lineItem, function (row) {
          return row.product_id;
        })
      );

      if (_this.lodash.isEmpty(validateLineItem)) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Select product/service then try again.")
        );
        return false;
      }

      if (!_this.$refs.invoiceForm.validate()) {
        return false;
      }

      _this.$store.dispatch(CLEAR_ERROR, {});
      const formData = {
        action: typeof type == "string" ? type : null,
        reference: _this.invoiceCreate.reference || null,
        po_number: _this.invoiceCreate.po_number || null,
        customer: _this.lodash.toSafeInteger(_this.invoiceCreate.customer) || 0,
        property: _this.lodash.toSafeInteger(_this.invoiceCreate.property) || 0,
        billing: _this.lodash.toSafeInteger(_this.invoiceCreate.billing) || 0,
        property_contact_person:
          _this.lodash.toSafeInteger(
            _this.invoiceCreate.property_contact_person
          ) || 0,
        billing_contact_person:
          _this.lodash.toSafeInteger(
            _this.invoiceCreate.billing_contact_person
          ) || 0,
        title: _this.invoiceCreate.title || null,
        ticket: _this.lodash.toSafeInteger(_this.invoiceCreate.ticket) || null,
        visit: _this.lodash.toSafeInteger(_this.invoiceCreate.visit) || null,
        attention: _this.invoiceCreate.attention || null,
        admin_remark: _this.noteAttachment.admin_notes || null,
        client_remark: _this.noteAttachment.client_notes || null,
        term_conditions: _this.termsCondition || null,
        payment_terms: _this.paymentTerms || null,
        additional_remarks: _this.lineItemCalculation.description || null,
        tax_applied: _this.lineItemCalculation.taxApplied,
        discount_value: _this.lineItemCalculation.discountValue,
        tax_value: _this.taxValue,
        adjustment: _this.lineItemCalculation.adjustmentAmount,
        discount_type: _this.lineItemCalculation.discountType,
        discount_value_type: _this.lineItemCalculation.discountValueType,
        property_person_notify:
          _this.lodash.toSafeInteger(
            _this.invoiceCreate.property_person_notify
          ) || 0,
        billing_person_notify:
          _this.lodash.toSafeInteger(
            _this.invoiceCreate.billing_person_notify
          ) || 0,
        issued_type:
          _this.lodash.toSafeInteger(_this.invoiceCreate.issued_type) || 0,
        payment_due:
          _this.lodash.toSafeInteger(_this.invoiceCreate.payment_due) || 0,
        invoice_date: _this.invoiceCreate.invoice_date || null,
        due_date: _this.invoiceCreate.due_date || null,
        documents: _this.noteAttachment.documents || [],
        custom_field: _this.customFields || [],
      };

      if (!formData.issued_type || formData.issued_type === 0) {
        formData.invoice_date = null;
      }

      if (formData.payment_due != 5) {
        formData.due_date = null;
      }

      let REQUEST_TYPE = POST;
      let REQUEST_URL = "invoice";
      if (_this.invoice && _this.invoice > 0) {
        REQUEST_TYPE = PUT;
        REQUEST_URL = "invoice/" + _this.invoice;
      }

      _this.formLoading = true;
      _this.$store
        .dispatch(REQUEST_TYPE, {
          url: REQUEST_URL,
          data: formData,
        })
        .then((response) => {
          _this.invoice = response.data.id;
          _this
            .CreateLineItems({
              type: "invoice",
              parent: _this.invoice,
              formData: _this.lineItem,
            })
            .then(() => {
              _this.$router.push(
                _this.getDefaultRoute("invoice", {
                  query: {
                    status: "all",
                  },
                })
              );
            })
            .catch((error) => {
              _this.logError(error);
            });
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
  },
  mounted() {
    const _this = this;
    _this.getVisitDetail();
    _this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Invoice",
        route: "invoice",
      },
      {
        title: "Create",
      },
    ]);

    _this.getAttributes([
      "customer",
      "property_contact_person",
      "billing_contact_person",
      "property",
      "billing",
      "barcode",
      "barcode_setting",
      "custom_field",
      "payment_terms",
    ]);
  },
  created() {
    const _this = this;
    _this.invoiceCreate.customer = _this.lodash.toSafeInteger(
      _this.$route.query.customer
    );
    _this.invoiceCreate.property = _this.lodash.toSafeInteger(
      _this.$route.query.property
    );
    _this.invoiceCreate.billing = _this.lodash.toSafeInteger(
      _this.$route.query.billing
    );

    if (!_this.invoiceCreate.customer || !_this.invoiceCreate.property) {
      _this.$router.go(-1);
    }

    if (_this.$route.name == "invoice-update") {
      _this.invoice = _this.$route.params.id;
      if (!_this.invoice) {
        _this.$router.go(-1);
      }
      _this.isUpdateMode = true;
      _this.isDuplicateMode = false;
    }

    if (
      _this.$route.name == "invoice-create" &&
      _this.$route.query.duplicate > 0
    ) {
      _this.duplicateInvoice = _this.$route.query.duplicate;
      _this.isDuplicateMode = true;
      _this.isUpdateMode = false;
    }

    _this.jobId = _this.lodash.toSafeInteger(_this.$route.query.job);
    _this.visitId = _this.lodash.toSafeInteger(_this.$route.query.visit);

    TermConditionEventBus.$on("update:term-condition", (argument) => {
      _this.termsCondition = argument;
    });

    TermConditionEventBus.$on("update:payment-terms", (argument) => {
      _this.paymentTerms = argument;
    });

    NoteAttachmentEventBus.$on("update:notes-attachment", (argument) => {
      _this.noteAttachment = argument;
    });
  },
  computed: {
    isPageLoading() {
      return this.pageLoading || this.formLoading;
    },
  },
};
